import Vue from "vue";
import { mapFilters } from "@/utils/newModule";

export default {
    namespaced: true,
    actions: {
        async getCategory(context, { c_id, mp, date, date2, fbs }) {
            const { data } = await Vue.api.get("/getCategory", {
                params: {
                    mp,
                    date,
                    date2,
                    c_id,
                    fbs,
                },
            });
            return data;
        },
        async getCategoryList(context, { c_id, mp }) {
            const { data } = await Vue.api.get("/getCategoryList", {
                params: {
                    mp,
                    c_id,
                },
            });
            return data;
        },
        async getCategoryByName(context, { query, mp }) {
            const { data } = await Vue.api.get("/getCategoryByName", {
                params: {
                    query,
                    mp,
                },
            });
            return data;
        },
        async categoryAnalyticCache(
            context,
            { mp, c_id, date, date2, fbs = false }
        ) {
            try {
                await Vue.api.post(
                    "/service/seller/ext-analitic/category_analytic_cache",
                    {
                        marketPlace: mp,
                        category: c_id,
                        dateFrom: date,
                        dateTo: date2,
                        fbs,
                    }
                );

                //console.log('53: category_analytic_cache: OK')

            } catch (e) {

                //console.log('53: category_analytic_cache: e=',e)

                if (
                    e?.response?.data?.errors &&
                    e?.response?.data?.message ===
                        "Tariff plan is not suitable."
                ) {
                    throw new Error(e.response.data.errors.countDay);
                } else {
                    throw new Error("no-limit-error");
                }
            }
        },
        async categoryOverviewAllOld(context, queryParams) {
            const { data } = await Vue.api.get("/categoryOverviewAll", {
                params: {
                    ...queryParams,
                },
            });
            return data;
        },
        async categoryOverviewAll(
            context,
            { mp, c_id, date, date2, fbs = false, filters, table }
        ) {
            const body = {
                marketPlace: mp,
                category: c_id,
                dateFrom: date,
                dateTo: date2,
                fbs,
                filter: filters,
            };
            if (body.filter) {
                body.filter = mapFilters(body.filter);
            }

            let data = null;
            switch (table) {
                case "products":
                    data = await Vue.api.post(
                        "/service/seller/ext-analitic/category_overview_all",
                        body
                    );
                    break;
                case "brands":
                    data = await Vue.api.post(
                        "/service/seller/ext-analitic/category_overview_all_brand",
                        body
                    );
                    break;
                case "sellers":
                    data = await Vue.api.post(
                        "/service/seller/ext-analitic/category_overview_all_seller",
                        body
                    );
                    break;
                case "subcategories":
                    data = await Vue.api.post(
                        "/service/seller/ext-analitic/category_overview_all_sub_category",
                        body
                    );
                    break;
                default:
                    throw new Error("Invalid filter type");
            }

            //console.log('118: data.data.data=',data.data.data)

            return data.data.data;
        },
        async categoryShareTotalRevenue(
            context,
            { mp, c_id, date, date2, fbs = false, filters, table }
        ) {
            const body = {
                marketPlace: mp,
                category: c_id,
                dateFrom: date,
                dateTo: date2,
                fbs,
            };
            if (filters && table !== "subcategories") {
                body.filter = mapFilters(filters);
            }
            //console.log(body);
            let data = null;
            switch (table) {
                case "products":
                    data = await Vue.api.post(
                        "/service/seller/ext-analitic/share_total_revenue",
                        body
                    );
                    break;
                case "brands":
                    data = await Vue.api.post(
                        "/service/seller/ext-analitic/share_total_revenue_brand",
                        body
                    );
                    break;
                case "sellers":
                    data = await Vue.api.post(
                        "/service/seller/ext-analitic/share_total_revenue_seller",
                        body
                    );
                    break;
                case "subcategories":
                    data = await Vue.api.post(
                        "/service/seller/ext-analitic/share_total_revenue",
                        body
                    );
                    break;
                default:
                    throw new Error("Invalid filter type");
            }

            // const { data } = await Vue.api.post(
            //     "/service/seller/ext-analitic/share_total_revenue",
            //     body
            // );
            return data.data;
        },
        async getCategoryProductsOld(context, queryParams) {
            const { mp, c_id, date, date2 } = queryParams;
            const { data } = await Vue.api.get("/getCategoryProducts", {
                params: {
                    mp,
                    c_id,
                    date,
                    date2,
                    // other params like pagination, sorting, filtering is dynamic
                    ...queryParams,
                },
            });
            return data;
        },
        async getCategoryProducts(
            context,
            {
                page = 1,
                page_size = 10,
                skip = 0,
                mp,
                c_id,
                date,
                date2,
                fbs = false,
                filters,
                sort,
                sort_dir,
                field,
            }
        ) {
            skip = (page - 1) * page_size;
            const body = {
                marketPlace: mp,
                category: c_id,
                dateFrom: date,
                dateTo: date2,
                fbs,
                filter: filters,
                take: page_size,
                pageSize: page_size,
                page,
                skip,
                field,
            };
            if (sort) {
                body.sort = sort;
            }
            if (sort_dir) {
                body.sort_dir = sort_dir;
            }
            if (body.filter) {
                body.filter = mapFilters(body.filter);
            }

            try {
                const { data } = await Vue.api.post(
                    "/service/seller/ext-analitic/get_category_product",
                    body
                );
                const result = data.data;

                if (result.ranges) {
                    result.ranges = result.ranges[0];
                    result.total = Number(result.ranges?.total);
                }
                if (!result.items) {
                    result.items = [];
                }
                return result;
            } catch (e) {
                if (
                    e?.response?.data?.errors &&
                    e?.response?.data?.message ===
                        "Tariff plan is not suitable."
                ) {
                    throw new Error(e.response.data.errors);
                } else {
                    throw new Error("no-limit-error");
                }
            }
        },
        async getCategoryBrandsOld(context, queryParams) {
            const { mp, c_id, date, date2 } = queryParams;
            const { data } = await Vue.api.get("/getCategoryBrands", {
                params: {
                    mp,
                    c_id,
                    date,
                    date2,
                    // other params like pagination, sorting, filtering is dynamic
                    ...queryParams,
                },
            });
            return data;
        },
        async getCategoryBrands(
            context,
            {
                page = 1,
                page_size = 10,
                skip = 0,
                mp,
                c_id,
                date,
                date2,
                fbs = false,
                filters,
                sort,
                sort_dir,
            }
        ) {
            skip = (page - 1) * page_size;
            const body = {
                marketPlace: mp,
                category: c_id,
                dateFrom: date,
                dateTo: date2,
                fbs,
                filter: filters,
                take: page_size,
                pageSize: page_size,
                page,
                skip,
            };
            if (sort) {
                body.sort = sort;
            }
            if (sort_dir) {
                body.sort_dir = sort_dir;
            }
            if (body.filter) {
                body.filter = mapFilters(body.filter);
            }

            try {
                const { data } = await Vue.api.post(
                    "/service/seller/ext-analitic/get_category_brand",
                    body
                );
                const result = data.data;
                if (result.ranges) {
                    result.ranges = result.ranges[0];
                    result.total = Number(result.ranges?.total);
                }
                if (!result.items) {
                    result.items = [];
                }
                return result;
            } catch (e) {
                if (
                    e?.response?.data?.errors &&
                    e?.response?.data?.message ===
                        "Tariff plan is not suitable."
                ) {
                    throw new Error(e.response.data.errors);
                } else {
                    throw new Error("no-limit-error");
                }
            }
        },
        async getCategorySellersOld(context, queryParams) {
            const { mp, c_id, date, date2 } = queryParams;
            const { data } = await Vue.api.get("/getCategorySellers", {
                params: {
                    mp,
                    c_id,
                    date,
                    date2,
                    // other params like pagination, sorting, filtering is dynamic
                    ...queryParams,
                },
            });
            return data;
        },
        async getCategorySellers(
            context,
            {
                page = 1,
                page_size = 10,
                skip = 0,
                mp,
                c_id,
                date,
                date2,
                fbs = false,
                filters,
                sort,
                sort_dir,
            }
        ) {
            skip = (page - 1) * page_size;
            const body = {
                marketPlace: mp,
                category: c_id,
                dateFrom: date,
                dateTo: date2,
                fbs,
                filter: filters,
                take: page_size,
                pageSize: page_size,
                page,
                skip,
            };
            if (sort) {
                body.sort = sort;
            }
            if (sort_dir) {
                body.sort_dir = sort_dir;
            }
            if (body.filter) {
                body.filter = mapFilters(body.filter);
            }

            try {
                const { data } = await Vue.api.post(
                    "/service/seller/ext-analitic/get_category_seller",
                    body
                );
                const result = data.data;
                if (result.ranges) {
                    result.ranges = result.ranges[0];
                    result.total = Number(result.ranges?.total);
                }
                if (!result.items) {
                    result.items = [];
                }
                return result;
            } catch (e) {
                if (
                    e?.response?.data?.errors &&
                    e?.response?.data?.message ===
                        "Tariff plan is not suitable."
                ) {
                    throw new Error(e.response.data.errors);
                } else {
                    throw new Error("no-limit-error");
                }
            }
        },
        async getCategorySubcategoriesOld(context, queryParams) {
            const { mp, c_id, date, date2 } = queryParams;
            const { data } = await Vue.api.get("/getCategorySubcategories", {
                params: {
                    mp,
                    c_id,
                    date,
                    date2,
                    // other params like pagination, sorting, filtering is dynamic
                    ...queryParams,
                },
            });
            return data;
        },
        async getCategorySubcategories(
            context,
            {
                page = 1,
                page_size = 10,
                skip = 0,
                mp,
                c_id,
                date,
                date2,
                fbs = false,
                filters,
                sort,
                sort_dir,
            }
        ) {
            skip = (page - 1) * page_size;
            const body = {
                marketPlace: mp,
                category: c_id,
                dateFrom: date,
                dateTo: date2,
                fbs,
                filter: filters,
                take: page_size,
                pageSize: page_size,
                page,
                skip,
            };
            try {
                await Vue.api.post(
                    "/service/seller/ext-analitic/category_sub_category",
                    body
                );
            } catch (e) {
                if (
                    e?.response?.data?.errors &&
                    e?.response?.data?.message ===
                        "Tariff plan is not suitable."
                ) {
                    throw new Error(e.response.data.errors.countDay);
                } else {
                    throw new Error("no-limit-error");
                }
            }
            if (sort) {
                body.sort = sort;
            }
            if (sort_dir) {
                body.sort_dir = sort_dir;
            }
            if (body.filter) {
                body.filter = mapFilters(body.filter);
            }
            try {
                const { data } = await Vue.api.post(
                    "/service/seller/ext-analitic/get_category_sub_category",
                    body
                );
                const result = data.data;
                if (result.ranges) {
                    result.ranges = result.ranges[0];
                    result.total = Number(result.ranges?.total);
                }
                if (!result.items) {
                    result.items = [];
                }
                return result;
            } catch (e) {
                if (
                    e?.response?.data?.errors &&
                    e?.response?.data?.message ===
                        "Tariff plan is not suitable."
                ) {
                    throw new Error(e.response.data.errors);
                } else {
                    throw new Error("no-limit-error");
                }
            }
        },
        //kwd
        async getCategoryKeywords(context, queryParams) {
            const { mp, c_id, date, date2 } = queryParams;
            const { data } = await Vue.api.get("/getCategoryKeywords", {
                params: {
                    mp,
                    c_id,
                    date,
                    date2,
                    // other params like pagination, sorting, filtering is dynamic
                    ...queryParams,
                },
            });
            return data;
        },
        async getCategorySegments(context, queryParams) {
            const { mp, c_id, date, date2 } = queryParams;
            const { data } = await Vue.api.get("/getCategorySegments", {
                params: {
                    mp,
                    c_id,
                    date,
                    date2,
                    // other params like pagination, sorting, filtering is dynamic
                    ...queryParams,
                },
            });
            return data;
        },
        async getCategoryFilter(
            context,
            { mp, c_id, date, date2, fbs = false, field, field_values_query }
        ) {
            const { data } = await Vue.api.post(
                "/service/seller/ext-analitic/get_filter_select",
                {
                    marketPlace: mp,
                    category: c_id,
                    dateFrom: date,
                    dateTo: date2,
                    fbs,
                    select: field,
                }
            );
            const result = {
                values: {
                    [field]: [
                        ...data.data.filter(
                            (item) =>
                                item &&
                                item
                                    .toLowerCase()
                                    .includes(field_values_query.toLowerCase())
                        ),
                    ],
                },
            };
            return result;
        },
    },
};
